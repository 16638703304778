// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderRange .horizontal-slider {
  padding-bottom: 20px;
  margin: 0 40px 0 25px;
}
.sliderRange .thumb {
  background-color: #fff;
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid #0098b3;
  border-radius: 50%;
  top: -4px;
}
.sliderRange .thumb span {
  position: absolute;
  color: var(--text-grey-2, #3a4243);
  /* Body/Text 2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  bottom: -25px;
  left: -10px;
}
.sliderRange .track {
  color: blue;
  height: 6px;
  border-radius: 4px;
}
.sliderRange .track-0 {
  background-color: #c3ecf4;
}
.sliderRange .track-1 {
  background-color: #0098b3;
}
.sliderRange .track-2 {
  background-color: #c3ecf4;
}`, "",{"version":3,"sources":["webpack://./src/components/SliderRange/sliderRange.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,qBAAA;AAAJ;AAEE;EACE,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;AAAJ;AACI;EACE,kBAAA;EACA,kCAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;AACN;AAEE;EACE,WAAA;EACA,WAAA;EACA,kBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".sliderRange {\r\n  .horizontal-slider {\r\n    padding-bottom: 20px;\r\n    margin: 0 40px 0 25px;\r\n  }\r\n  .thumb {\r\n    background-color: #fff;\r\n    position: relative;\r\n    width: 14px;\r\n    height: 14px;\r\n    border: 2px solid #0098b3;\r\n    border-radius: 50%;\r\n    top: -4px;\r\n    span {\r\n      position: absolute;\r\n      color: var(--text-grey-2, #3a4243);\r\n      /* Body/Text 2 */\r\n      font-size: 14px;\r\n      font-style: normal;\r\n      font-weight: 400;\r\n      line-height: 135%;\r\n      bottom: -25px;\r\n      left: -10px;\r\n    }\r\n  }\r\n  .track {\r\n    color: blue;\r\n    height: 6px;\r\n    border-radius: 4px;\r\n  }\r\n  .track-0 {\r\n    background-color: #c3ecf4;\r\n  }\r\n  .track-1 {\r\n    background-color: #0098b3;\r\n  }\r\n  .track-2 {\r\n    background-color: #c3ecf4;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
