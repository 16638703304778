// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadChart {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.loadChart h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #677787;
  margin-bottom: 26px;
}
.loadChartTitlePhn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.loadChartSection {
  transition: all 0.4s ease;
  overflow: hidden;
}
@media (max-width: 768px) {
  .loadChart {
    max-width: none;
    border-radius: 4px;
    padding: 16px;
  }
}
@media (max-width: 1150px) {
  .loadChart h3 {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadChart/loadChart.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EAEA,mBAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AADJ;AAIE;EACE,yBAAA;EACA,gBAAA;AAFJ;AAKE;EA5BF;IA6BI,eAAA;IACA,kBAAA;IACA,aAAA;EAFF;AACF;AAIE;EACE;IACE,gBAAA;EAFJ;AACF","sourcesContent":[".loadChart {\r\n  padding: 24px;\r\n  background-color: #fff;\r\n  // max-width: 570px;\r\n  border-radius: 12px;\r\n  margin-bottom: 16px;\r\n\r\n  h3 {\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 130%;\r\n    letter-spacing: 0.5px;\r\n    color: #677787;\r\n    margin-bottom: 26px;\r\n  }\r\n\r\n  &TitlePhn {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    cursor: pointer;\r\n  }\r\n\r\n  &Section {\r\n    transition: all 0.4s ease;\r\n    overflow: hidden;\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    max-width: none;\r\n    border-radius: 4px;\r\n    padding: 16px;\r\n  }\r\n\r\n  @media (max-width: 1150px) {\r\n    h3 {\r\n      margin-bottom: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
