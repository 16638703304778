const TransportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M14.5 8H16.0194C16.3232 8 16.6105 8.13809 16.8002 8.3753L18.2809 10.2261C18.4227 10.4034 18.5 10.6237 18.5 10.8508V14C18.5 14.5523 18.0523 15 17.5 15H16M14.5 8V6.5C14.5 5.94772 14.0523 5.5 13.5 5.5H4.5C3.94772 5.5 3.5 5.94772 3.5 6.5V14C3.5 14.5523 3.94772 15 4.5 15H6M14.5 8V13.5M16 15V15C16 14.1716 15.3284 13.5 14.5 13.5V13.5M16 15V15C16 15.8284 15.3284 16.5 14.5 16.5V16.5C13.6716 16.5 13 15.8284 13 15V15M13 15V15C13 14.1716 13.6716 13.5 14.5 13.5V13.5M13 15H9M9 15V15C9 14.1716 8.32843 13.5 7.5 13.5V13.5C6.67157 13.5 6 14.1716 6 15V15M9 15V15C9 15.8284 8.32843 16.5 7.5 16.5V16.5C6.67157 16.5 6 15.8284 6 15V15"
      stroke="#A3A3A3"
      strokeWidth="1.25"
    />
  </svg>
);

export default TransportIcon;
