const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1019_14433)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53026 10.3065C9.38962 10.4232 9.19889 10.4887 9.00001 10.4887C8.80114 10.4887 8.61041 10.4232 8.46976 10.3065L4.22701 6.78507C4.15538 6.72765 4.09824 6.65896 4.05894 6.58302C4.01963 6.50707 3.99894 6.42539 3.99808 6.34274C3.99721 6.26008 4.01619 6.17812 4.0539 6.10161C4.09161 6.02511 4.1473 5.95561 4.21772 5.89717C4.28814 5.83872 4.37188 5.7925 4.46405 5.7612C4.55622 5.7299 4.65498 5.71415 4.75456 5.71487C4.85415 5.71559 4.95256 5.73276 5.04407 5.76538C5.13557 5.79801 5.21833 5.84543 5.28751 5.90488L9.00001 8.98618L12.7125 5.90488C12.854 5.79149 13.0434 5.72875 13.2401 5.73017C13.4367 5.73158 13.6248 5.79705 13.7639 5.91246C13.9029 6.02788 13.9818 6.184 13.9835 6.34722C13.9852 6.51043 13.9096 6.66767 13.773 6.78507L9.53026 10.3065Z"
        fill="#A3A3A3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1019_14433">
        <rect
          width="18"
          height="14.9396"
          fill="white"
          transform="translate(0 0.529297)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Arrow;
