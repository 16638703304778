const TransportIconActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_2811_4447)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.625C4.79289 6.625 4.625 6.79289 4.625 7V14.5C4.625 14.7071 4.79289 14.875 5 14.875H5.9684C6.23529 14.0064 7.04391 13.375 8 13.375C8.95609 13.375 9.76471 14.0064 10.0316 14.875H12.9684C13.1746 14.204 13.704 13.6746 14.375 13.4684V7C14.375 6.79289 14.2071 6.625 14 6.625H5ZM15.625 13.4684C16.296 13.6746 16.8254 14.204 17.0316 14.875H18C18.2071 14.875 18.375 14.7071 18.375 14.5V11.3508C18.375 11.2656 18.346 11.183 18.2928 11.1165L16.8122 9.26574C16.741 9.17678 16.6333 9.125 16.5194 9.125H15.625V13.4684ZM15.625 7.875V7C15.625 6.10254 14.8975 5.375 14 5.375H5C4.10254 5.375 3.375 6.10254 3.375 7V14.5C3.375 15.3975 4.10254 16.125 5 16.125H5.9684C6.23529 16.9936 7.04391 17.625 8 17.625C8.95609 17.625 9.76471 16.9936 10.0316 16.125H12.9684C13.2353 16.9936 14.0439 17.625 15 17.625C15.9561 17.625 16.7647 16.9936 17.0316 16.125H18C18.8975 16.125 19.625 15.3975 19.625 14.5V11.3508C19.625 10.9818 19.4994 10.6238 19.2689 10.3357L17.7883 8.48487C17.4799 8.0994 17.013 7.875 16.5194 7.875H15.625ZM8 14.625C7.51675 14.625 7.125 15.0168 7.125 15.5C7.125 15.9832 7.51675 16.375 8 16.375C8.48325 16.375 8.875 15.9832 8.875 15.5C8.875 15.0168 8.48325 14.625 8 14.625ZM15 14.625C14.5168 14.625 14.125 15.0168 14.125 15.5C14.125 15.9832 14.5168 16.375 15 16.375C15.4832 16.375 15.875 15.9832 15.875 15.5C15.875 15.0168 15.4832 14.625 15 14.625Z"
        fill="#0098B3"
      />
      <path
        d="M4.625 7C4.625 6.79289 4.79289 6.625 5 6.625H14C14.2071 6.625 14.375 6.79289 14.375 7V13.4684C13.704 13.6746 13.1746 14.204 12.9684 14.875H10.0316C9.76471 14.0064 8.95609 13.375 8 13.375C7.04391 13.375 6.23529 14.0064 5.9684 14.875H5C4.79289 14.875 4.625 14.7071 4.625 14.5V7Z"
        fill="#0098B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_2811_4447">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TransportIconActive;
