// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html,
body {
  font-size: 10px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

.collapsed {
  justify-content: center;
}

.no-scroll {
  overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #3a4243;
  color: white;
  padding-right: 12px;
}

.gm-ui-hover-effect > span {
  background-color: white;
}

.gm-style-iw-d {
  padding-right: 12px;
  padding-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,oCAAA;AADF;;AAIA;;EAEE,eAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,qBAAA;EACA,eAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;;EAEE,wBAAA;EACA,SAAA;AADF;;AAQA;;;;EAIE,mBAAA;EACA,YAAA;EACA,mBAAA;AALF;;AAQA;EACE,uBAAA;AALF;;AAQA;EACE,mBAAA;EACA,oBAAA;AALF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap\");\r\n@import url(\"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap\");\r\n@import \"styles/variables.scss\";\r\n\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n  font-family: \"Open Sans\", sans-serif;\r\n}\r\n\r\nhtml,\r\nbody {\r\n  font-size: 10px;\r\n}\r\n\r\nimg {\r\n  max-width: 100%;\r\n}\r\n\r\na {\r\n  text-decoration: none;\r\n  cursor: pointer;\r\n}\r\n\r\nul {\r\n  list-style: none;\r\n}\r\n\r\n.collapsed {\r\n  justify-content: center;\r\n}\r\n\r\n.no-scroll {\r\n  overflow: hidden;\r\n}\r\n\r\ninput[type=\"number\"]::-webkit-inner-spin-button,\r\ninput[type=\"number\"]::-webkit-outer-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}\r\n\r\n// input:focus {\r\n//   outline: none !important;\r\n// }\r\n\r\n.gm-style .gm-style-iw-d::-webkit-scrollbar-track,\r\n.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,\r\n.gm-style .gm-style-iw-c,\r\n.gm-style .gm-style-iw-t::after {\r\n  background: #3a4243;\r\n  color: white;\r\n  padding-right: 12px;\r\n}\r\n\r\n.gm-ui-hover-effect > span {\r\n  background-color: white;\r\n}\r\n\r\n.gm-style-iw-d {\r\n  padding-right: 12px;\r\n  padding-bottom: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
