export const profileDetails = [
  // {
  //   key: "userId",
  //   title: "User ID",
  //   value: "TS1212213"
  // },
  {
    key: "mobile",
    title: "Phone Number",
    value: "+91 9037612345"
  },
  {
    key: "email",
    title: "Email Address",
    value: "user@senzcraft.com"
  },
  {
    key: "organization_name",
    title: "Company Name",
    value: "XYZ Solutions"
  }
];
