// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fuelChart {
  padding: 24px;
  background-color: #fff;
  max-width: 570px;
  border-radius: 12px;
  width: 100%;
}
.fuelChartHeading {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 22px;
}
.fuelChartHeading h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #677787;
}
.fuelChartHeading .breadcrumb {
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  border-radius: 4px;
  background-color: #f0fafa;
  padding: 4px 8px;
  cursor: default;
}
.fuelChartHeading .breadcrumb .active {
  color: #2592cb;
  text-decoration: underline;
  cursor: pointer;
}
.fuelChartHeading .breadcrumb .notActive {
  text-decoration: none;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/FuelChart/fuelChart.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AACN;AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AAAN;AAEM;EACE,cAAA;EACA,0BAAA;EACA,eAAA;AAAR;AAEM;EACE,qBAAA;EACA,eAAA;AAAR","sourcesContent":[".fuelChart {\r\n  padding: 24px;\r\n  background-color: #fff;\r\n  max-width: 570px;\r\n  border-radius: 12px;\r\n  width: 100%;\r\n\r\n  &Heading {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 14px;\r\n    margin-bottom: 22px;\r\n\r\n    h3 {\r\n      font-size: 14px;\r\n      font-weight: 600;\r\n      line-height: 130%;\r\n      letter-spacing: 0.5px;\r\n      color: #677787;\r\n    }\r\n\r\n    .breadcrumb {\r\n      font-size: 12px;\r\n      font-weight: 400;\r\n      line-height: 130%;\r\n      border-radius: 4px;\r\n      background-color: #f0fafa;\r\n      padding: 4px 8px;\r\n      cursor: default;\r\n\r\n      .active {\r\n        color: #2592cb;\r\n        text-decoration: underline;\r\n        cursor: pointer;\r\n      }\r\n      .notActive {\r\n        text-decoration: none;\r\n        cursor: default;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
