const SignOut = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7 13.5C7 13.6326 6.94732 13.7598 6.85355 13.8536C6.75978 13.9473 6.63261 14 6.5 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6.5C6.63261 2 6.75978 2.05268 6.85355 2.14645C6.94732 2.24021 7 2.36739 7 2.5C7 2.63261 6.94732 2.75979 6.85355 2.85355C6.75978 2.94732 6.63261 3 6.5 3H3V13H6.5C6.63261 13 6.75978 13.0527 6.85355 13.1464C6.94732 13.2402 7 13.3674 7 13.5ZM13.8537 7.64625L11.3537 5.14625C11.2599 5.05243 11.1327 4.99972 11 4.99972C10.8673 4.99972 10.7401 5.05243 10.6462 5.14625C10.5524 5.24007 10.4997 5.36732 10.4997 5.5C10.4997 5.63268 10.5524 5.75993 10.6462 5.85375L12.2931 7.5H6.5C6.36739 7.5 6.24021 7.55268 6.14645 7.64645C6.05268 7.74021 6 7.86739 6 8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24021 8.44732 6.36739 8.5 6.5 8.5H12.2931L10.6462 10.1462C10.5524 10.2401 10.4997 10.3673 10.4997 10.5C10.4997 10.6327 10.5524 10.7599 10.6462 10.8538C10.7401 10.9476 10.8673 11.0003 11 11.0003C11.1327 11.0003 11.2599 10.9476 11.3537 10.8538L13.8537 8.35375C13.9002 8.30731 13.9371 8.25217 13.9623 8.19147C13.9874 8.13077 14.0004 8.06571 14.0004 8C14.0004 7.93429 13.9874 7.86923 13.9623 7.80853C13.9371 7.74783 13.9002 7.69269 13.8537 7.64625Z"
      fill="#0098B3"
      stroke="#0098B3"
      strokeWidth="0.3"
    />
  </svg>
);

export default SignOut;
