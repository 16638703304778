const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 5.125C4.74061 5.125 4.125 5.74061 4.125 6.5V15.5C4.125 16.2594 4.74061 16.875 5.5 16.875H8.875V5.125H5.5ZM2.875 6.5C2.875 5.05025 4.05025 3.875 5.5 3.875H9.5C9.84518 3.875 10.125 4.15482 10.125 4.5V17.5C10.125 17.8452 9.84518 18.125 9.5 18.125H5.5C4.05025 18.125 2.875 16.9497 2.875 15.5V6.5ZM11.875 4.5C11.875 4.15482 12.1548 3.875 12.5 3.875H17.5C18.3975 3.875 19.125 4.60254 19.125 5.5V8.5C19.125 9.39746 18.3975 10.125 17.5 10.125H12.5C12.1548 10.125 11.875 9.84518 11.875 9.5V4.5ZM13.125 5.125V8.875H17.5C17.7071 8.875 17.875 8.70711 17.875 8.5V5.5C17.875 5.29289 17.7071 5.125 17.5 5.125H13.125ZM11.875 12.5C11.875 12.1548 12.1548 11.875 12.5 11.875H17.5C18.3975 11.875 19.125 12.6025 19.125 13.5V16.5C19.125 17.3975 18.3975 18.125 17.5 18.125H12.5C12.1548 18.125 11.875 17.8452 11.875 17.5V12.5ZM13.125 13.125V16.875H17.5C17.7071 16.875 17.875 16.7071 17.875 16.5V13.5C17.875 13.2929 17.7071 13.125 17.5 13.125H13.125Z"
      fill="#A3A3A3"
    />
  </svg>
);

export default DashboardIcon;
