const DashboardIconActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M12.5 12.5V17.5H17.5C18.0523 17.5 18.5 17.0523 18.5 16.5V13.5C18.5 12.9477 18.0523 12.5 17.5 12.5H12.5Z"
      fill="#0098B3"
    />
    <path
      d="M12.5 4.5V9.5H17.5C18.0523 9.5 18.5 9.05228 18.5 8.5V5.5C18.5 4.94772 18.0523 4.5 17.5 4.5H12.5Z"
      fill="#0098B3"
    />
    <path
      d="M9.5 17.5V4.5H5.5C4.39543 4.5 3.5 5.39543 3.5 6.5V15.5C3.5 16.6046 4.39543 17.5 5.5 17.5H9.5Z"
      fill="#0098B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.875 6.5C2.875 5.05025 4.05025 3.875 5.5 3.875H9.5C9.84518 3.875 10.125 4.15482 10.125 4.5V17.5C10.125 17.8452 9.84518 18.125 9.5 18.125H5.5C4.05025 18.125 2.875 16.9497 2.875 15.5V6.5ZM11.875 4.5C11.875 4.15482 12.1548 3.875 12.5 3.875H17.5C18.3975 3.875 19.125 4.60254 19.125 5.5V8.5C19.125 9.39746 18.3975 10.125 17.5 10.125H12.5C12.1548 10.125 11.875 9.84518 11.875 9.5V4.5ZM11.875 12.5C11.875 12.1548 12.1548 11.875 12.5 11.875H17.5C18.3975 11.875 19.125 12.6025 19.125 13.5V16.5C19.125 17.3975 18.3975 18.125 17.5 18.125H12.5C12.1548 18.125 11.875 17.8452 11.875 17.5V12.5Z"
      fill="#0098B3"
    />
  </svg>
);

export default DashboardIconActive;
