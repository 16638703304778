const Marketing = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M4.5 12.5H3.875C3.875 12.8452 4.15482 13.125 4.5 13.125V12.5ZM4.5 8V7.375C4.15482 7.375 3.875 7.65482 3.875 8H4.5ZM17 4.5H17.625C17.625 4.28908 17.5186 4.09238 17.3421 3.97693C17.1656 3.86149 16.9427 3.84286 16.7495 3.9274L17 4.5ZM17 16L16.7495 16.5726C16.9427 16.6571 17.1656 16.6385 17.3421 16.5231C17.5186 16.4076 17.625 16.2109 17.625 16H17ZM9 17V17.625C9.34518 17.625 9.625 17.3452 9.625 17H9ZM7.5 17L6.92887 17.2538C7.02918 17.4795 7.25301 17.625 7.5 17.625V17ZM3.5 11.5H2.875C2.875 11.8452 3.15482 12.125 3.5 12.125V11.5ZM3.5 9V8.375C3.15482 8.375 2.875 8.65482 2.875 9H3.5ZM18.5 9L19.02 8.65331C18.8673 8.42415 18.5825 8.32201 18.3189 8.40182C18.0553 8.48163 17.875 8.72458 17.875 9H18.5ZM18.5 12H17.875C17.875 12.2754 18.0553 12.5184 18.3189 12.5982C18.5825 12.678 18.8673 12.5758 19.02 12.3467L18.5 12ZM8.375 8V12.5H9.625V8H8.375ZM4.5 8.625H9V7.375H4.5V8.625ZM9.25051 8.5726L17.2505 5.0726L16.7495 3.9274L8.74949 7.4274L9.25051 8.5726ZM16.375 4.5V16H17.625V4.5H16.375ZM17.2505 15.4274L9.25051 11.9274L8.74949 13.0726L16.7495 16.5726L17.2505 15.4274ZM8.375 12.5V17H9.625V12.5H8.375ZM9 16.375H7.5V17.625H9V16.375ZM9 11.875H5.5V13.125H9V11.875ZM5.5 11.875H4.5V13.125H5.5V11.875ZM8.07113 16.7462L6.07113 12.2462L4.92887 12.7538L6.92887 17.2538L8.07113 16.7462ZM5.125 12.5V11.5H3.875V12.5H5.125ZM4.5 10.875H3.5V12.125H4.5V10.875ZM4.125 11.5V9H2.875V11.5H4.125ZM5.125 11.5V9H3.875V11.5H5.125ZM5.125 9V8H3.875V9H5.125ZM3.5 9.625H4.5V8.375H3.5V9.625ZM17.875 9V12H19.125V9H17.875ZM19.02 12.3467L19.2804 11.9561L18.2404 11.2627L17.98 11.6533L19.02 12.3467ZM19.2804 9.04391L19.02 8.65331L17.98 9.34669L18.2404 9.73729L19.2804 9.04391ZM19.2804 11.9561C19.8683 11.0744 19.8683 9.92565 19.2804 9.04391L18.2404 9.73729C18.5483 10.1991 18.5483 10.8009 18.2404 11.2627L19.2804 11.9561Z"
      fill="#A3A3A3"
    />
  </svg>
);

export default Marketing;
