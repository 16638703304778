// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reeferChart {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
}
.reeferChart h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #677787;
  margin-bottom: 38px;
}
.reeferChartSection {
  transition: all 0.4s ease;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 100%;
}
@media (max-width: 768px) {
  .reeferChart {
    padding-bottom: 24px;
    border-radius: 4px;
    padding: 16px;
    max-width: none;
  }
}
@media (max-width: 1150px) {
  .reeferChart h3 {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ReeferChart/reeferChart.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EAEA,mBAAA;AAAF;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,mBAAA;AADJ;AAIE;EACE,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;AAFJ;AAKE;EAxBF;IAyBI,oBAAA;IACA,kBAAA;IACA,aAAA;IACA,eAAA;EAFF;AACF;AAGE;EACE;IACE,gBAAA;EADJ;AACF","sourcesContent":[".reeferChart {\r\n  padding: 24px;\r\n  background-color: #fff;\r\n  // max-width: 570px;\r\n  border-radius: 12px;\r\n  // padding-bottom: 50px;\r\n\r\n  h3 {\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 130%;\r\n    letter-spacing: 0.5px;\r\n    color: #677787;\r\n    margin-bottom: 38px;\r\n  }\r\n\r\n  &Section {\r\n    transition: all 0.4s ease;\r\n    overflow: hidden;\r\n    display: flex;\r\n    align-items: flex-start;\r\n    width: 100%;\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    padding-bottom: 24px;\r\n    border-radius: 4px;\r\n    padding: 16px;\r\n    max-width: none;\r\n  }\r\n  @media (max-width: 1150px) {\r\n    h3 {\r\n      margin-bottom: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
