const MarketingIconActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8421 4.47694C17.0186 4.59238 17.125 4.78908 17.125 5V16.5C17.125 16.7109 17.0186 16.9076 16.8421 17.0231C16.6656 17.1385 16.4427 17.1571 16.2495 17.0726L9.125 13.9556V17.5C9.125 17.8452 8.84518 18.125 8.5 18.125H7C6.75301 18.125 6.52918 17.9795 6.42887 17.7538L4.59383 13.625H4C3.65482 13.625 3.375 13.3452 3.375 13V12.625H2.975C2.64363 12.625 2.375 12.3564 2.375 12.025V9.475C2.375 9.14363 2.64363 8.875 2.975 8.875H3.375V8.5C3.375 8.15482 3.65482 7.875 4 7.875H8.36926L16.2495 4.4274C16.4427 4.34286 16.6656 4.36149 16.8421 4.47694ZM7.875 9.125H4.625V12.375H7.875V9.125ZM17.8189 8.90182C18.0825 8.82201 18.3673 8.92415 18.52 9.15331L18.7804 9.54391C19.3683 10.4257 19.3683 11.5744 18.7804 12.4561L18.52 12.8467C18.3673 13.0759 18.0825 13.178 17.8189 13.0982C17.5553 13.0184 17.375 12.7754 17.375 12.5V9.5C17.375 9.22459 17.5553 8.98163 17.8189 8.90182Z"
      fill="#0098B3"
    />
  </svg>
);
export default MarketingIconActive;
