// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartCard {
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  width: 49%;
  position: relative;
}
.chartCard .breadcrumb {
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  border-radius: 4px;
  background-color: #f0fafa;
  padding: 4px 8px;
  cursor: default;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: -42px;
  left: 224px;
}
.chartCard .active {
  color: #2592cb;
  text-decoration: underline;
  cursor: pointer;
}
.chartCard .notActive {
  text-decoration: none;
  cursor: default;
}
.chartCardHeading {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 22px;
}
.chartCardHeading h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #677787;
}

@media (max-width: 768px) {
  .chartCard .breadcrumb {
    top: -20px;
    left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Charts/ChartCard/chartCard.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;AADF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AAFJ;AAKE;EACE,cAAA;EACA,0BAAA;EACA,eAAA;AAHJ;AAKE;EACE,qBAAA;EACA,eAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAJJ;AAMI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAJN;;AASA;EAEI;IACE,UAAA;IACA,OAAA;EAPJ;AACF","sourcesContent":["@import \"styles/variables.scss\";\r\n\r\n.chartCard {\r\n  padding: 24px;\r\n  background-color: #fff;\r\n  border-radius: 12px;\r\n  width: 49%;\r\n  position: relative;\r\n  // max-width: 522px;\r\n\r\n  .breadcrumb {\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    line-height: 130%;\r\n    border-radius: 4px;\r\n    background-color: #f0fafa;\r\n    padding: 4px 8px;\r\n    cursor: default;\r\n    width: fit-content;\r\n    position: absolute;\r\n    top: -42px;\r\n    left: 224px;\r\n  }\r\n\r\n  .active {\r\n    color: #2592cb;\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n  }\r\n  .notActive {\r\n    text-decoration: none;\r\n    cursor: default;\r\n  }\r\n\r\n  &Heading {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 14px;\r\n    margin-bottom: 22px;\r\n\r\n    h3 {\r\n      font-size: 14px;\r\n      font-weight: 600;\r\n      line-height: 130%;\r\n      letter-spacing: 0.5px;\r\n      color: #677787;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .chartCard {\r\n    .breadcrumb {\r\n      top: -20px;\r\n      left: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
