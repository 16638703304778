const FinanceIconActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.625 3C3.97018 3 4.25 3.27982 4.25 3.625V18.5H19.125C19.4702 18.5 19.75 18.7798 19.75 19.125C19.75 19.4702 19.4702 19.75 19.125 19.75H3.625C3.27982 19.75 3 19.4702 3 19.125V3.625C3 3.27982 3.27982 3 3.625 3ZM11.125 5C11.4702 5 11.75 5.27982 11.75 5.625V16.625C11.75 16.9702 11.4702 17.25 11.125 17.25C10.7798 17.25 10.5 16.9702 10.5 16.625V5.625C10.5 5.27982 10.7798 5 11.125 5ZM7.625 8C7.97018 8 8.25 8.27982 8.25 8.625V16.625C8.25 16.9702 7.97018 17.25 7.625 17.25C7.27982 17.25 7 16.9702 7 16.625V8.625C7 8.27982 7.27982 8 7.625 8ZM14.625 12C14.9702 12 15.25 12.2798 15.25 12.625V16.625C15.25 16.9702 14.9702 17.25 14.625 17.25C14.2798 17.25 14 16.9702 14 16.625V12.625C14 12.2798 14.2798 12 14.625 12Z"
      fill="#0098B3"
    />
  </svg>
);
export default FinanceIconActive;
